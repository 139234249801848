<template>
    <div>
        <div id="user-package" v-if="packageHistory">
            <div class="ttc">
                <vx-card :title="$t('menuPackage.generalInformation')" class="mb-base"
                         style="color: #24243a; min-height: 345px">
                    <div class="vx-row color">
                        <div class="vx-col md:w-1/3 w-full" style="text-align: right;">
                            <span>{{ $t('menuPackage.organizationName') }}</span></div>
                        <div class="vx-col md:w-2/3 w-full "><span><b>{{ userInfo.username }}</b></span></div>
                    </div>
                    <div class="vx-row mt-5 color">
                        <div class="vx-col md:w-1/3 w-full" style="text-align: right;">
                            <span>{{ $t('menuPackage.packageName') }}</span></div>
                        <div class="vx-col md:w-2/3 w-full ">
                            <span><b>{{ packageHistory.organizationPackageResponseDTO.packageName }}</b></span>
                        </div>
                    </div>
                    <div class="vx-row mt-5 color">
                        <div class="vx-col md:w-1/3 w-full" style="text-align: right;">
                            <span>{{ $t('menuPackage.endDate') }}</span></div>
                        <div class="vx-col md:w-2/3 w-full ">
                            <span><b>{{ packageHistory.endDateString }}</b></span>
                            <span v-if="this.packageHistory.endDate"
                                  style="margin-left: 10px; color: red;">{{ $t('menuPackage.expired') }}</span>
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="detail">
                <vx-card :title="packageHistory.organizationPackageResponseDTO.packageName" class="mb-base">
                    <div class="vs-row mt-2">
                        <feather-icon icon="CheckIcon" class="action-success icon-btn"
                                      svgClasses="h-5 w-5 hover:text-primary"/>
                        <span>{{
                                packageHistory.organizationPackageResponseDTO.timeStorage
                            }} {{ $t('menuPackage.storageDate') }}</span>
                    </div>
                    <div class="vs-row mt-2">
                        <feather-icon icon="CheckIcon" class="action-success icon-btn"
                                      svgClasses="h-5 w-5 hover:text-primary"/>
                        <span>{{ this.packageHistory.organizationPackageResponseDTO.s3_quota }} GB {{ $t('menuPackage.storageCapacity') }}</span>
                    </div>
                    <div class="vs-row mt-2">
                        <feather-icon icon="CheckIcon" class="action-success icon-btn"
                                      svgClasses="h-5 w-5 hover:text-primary"/>
                        <span>{{ this.packageHistory.organizationPackageResponseDTO.totalCamera }} camera</span></div>
                    <div class="vs-row mt-2">
                        <feather-icon icon="CheckIcon" class="action-success icon-btn"
                                      svgClasses="h-5 w-5 hover:text-primary"/>
                        <span>{{ this.packageHistory.organizationPackageResponseDTO.totalCameraAI }} {{ $t('menuPackage.useAI') }}</span>
                    </div>
                    <div class="vs-row mt-2">
                        <feather-icon icon="CheckIcon" class="action-success icon-btn"
                                      svgClasses="h-5 w-5 hover:text-primary"/>
                        <span>{{ this.packageHistory.organizationPackageResponseDTO.profileName }} {{ $t('menuPackage.useAI') }}</span>
                    </div>
                    <div class="vs-row mt-2">
                        <feather-icon icon="CheckIcon" class="action-success icon-btn"
                                      svgClasses="h-5 w-5 hover:text-primary"/>
                        <span>{{ this.packageHistory.organizationPackageResponseDTO.packageDuration }} {{ $t('menuPackage.date') }}</span>
                    </div>
                    <h6 class="mt-5 mb-2">{{ $t('menuPackage.functional') }}</h6>
                    <div class="vs-row mt-2"
                         v-for="(item, index) in this.packageHistory.organizationPackageResponseDTO.permissionFunctions"
                         :key="index">
                        <feather-icon icon="PackageIcon" class="action-success icon-btn"
                                      svgClasses="h-5 w-5 hover:text-primary"/>
                        <span>{{ item.functionAlias }}</span></div>
                </vx-card>
            </div>
        </div>
        <div v-if="!packageHistory">
            <ErrorFunctionZero></ErrorFunctionZero>
        </div>
    </div>
</template>

<style lang="scss" scoped>
[dir=ltr] .icon-btn {
    margin-left: 15px;
    vertical-align: bottom;
    width: 13px;
    margin-right: 10px;
}

#user-package {
    display: flex;
}

.ttc {
    width: 65%;
    margin-right: 1%;
}

.detail {
    width: 33%;
    float: right;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: .8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

</style>

<script>
import moment from 'moment'
import ErrorFunctionZero from "../error-function/ErrorFunctionZero";

export default {
    components: {ErrorFunctionZero},
    data() {
        return {
            userInfo: {},
            packageHistory: null
        }
    },

    watch: {},

    computed: {},

    created() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo') || {});
        this.initScreen();
    },

    methods: {
        initScreen() {
            this.$vs.loading();
            let url = '/organization-package-history/find-by-org-active';
            this.$crm.get(url).then((response) => {
                if (response.data.length > 0) {
                    this.packageHistory = response.data[0];
                    this.packageHistory.endDate = (new Date(this.packageHistory.createdDate).getTime() +
                        this.packageHistory.organizationPackageResponseDTO.packageDuration * 1000 * 60 * 60 * 24) < new Date().getTime() ? true : false;
                    this.packageHistory.endDateString = moment(new Date(this.packageHistory.createdDate).getTime() +
                        this.packageHistory.organizationPackageResponseDTO.packageDuration * 1000 * 60 * 60 * 24).format("DD/MM/YYYY");
                    let profile = this.packageHistory.organizationPackageResponseDTO.profile;
                    this.packageHistory.organizationPackageResponseDTO.profileName = profile == 1 ? "360p" : profile == 2 ? "480p"
                        : profile == 3 ? "640p" : profile == 8 ? "720p" : profile == 9 ? "1080p" : ""
                }
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
    },
}

</script>

