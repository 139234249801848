<template>
    <div class="vx-card p-6 function-zero">
        <H3 class="color">Bạn chưa đăng kí gói dịch vụ nào.</H3>
        <vs-button class="ml-5" @click="toUserPackage">Đăng ký gói dịch vụ</vs-button>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {}
    },
    created() {
        this.initScreen()
    },
    watch: {},
    methods: {
        toUserPackage() {
            this.$router.push("/user/register-organization-package");
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
.function-zero {
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid green;
}
</style>
